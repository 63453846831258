<template>
  <div class="main__wrapper">
    <div class="page-task" v-loading="loading">
      <div class="task-header">
        <div class="task-header-text">{{$t('h.sideBar.task')}}</div>
      </div>
      <div class="operate-box">
        <div class="filter-box">
          <p>{{$t('h.task.uploadTaskName') + ':' }}</p>
          <el-input
            clearable
            v-model="taskName"
            :placeholder=" $t('h.song.taskNameMsg')"
            @blur="searchList()"
            @clear="searchList()"
          ></el-input>
        </div>
        <div class="task-header-bottom" @click="toMark">{{$t('h.task.addTask')}}</div>
      </div>
      <el-table
        :data="tempList"
        class="task-table"
        :header-cell-style="{
          color: '#aaa',
          background: '#F5F7FA',
        }"
      >
        <my-empty slot="empty" :empty-text="$t('h.song.emptyText')"></my-empty>
        <el-table-column
          property="task_name"
          :label="$t('h.task.uploadTaskName')"
          min-width="20%"
          show-overflow-tooltip
          class="taskName"
        ></el-table-column>
        <el-table-column
          property="create_time"
          :label="$t('h.task.releaseTime')"
          min-width="20%"
          show-overflow-tooltip
        ></el-table-column
        ><el-table-column 
          min-width="15%" 
          show-overflow-tooltip 
          :label="$t('h.task.scoreSence')"
          v-if="$store.state.user.country == 'China'"
          >
          <template slot-scope="scope">
            <div class="scene_type" v-if="scope.row.scene_type == 'purchase'">
              Demo筛选
            </div>
            <div
              class="scene_type"
              v-else-if="scope.row.scene_type == 'evaluation'"
            >
              成品评估
            </div>
          </template>
        </el-table-column>
        <el-table-column  
          min-width="15%"
          property="status"
          :label="$t('h.task.status')"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{ getFinishedTime(scope.row.status) }}
          </template>
        </el-table-column>
        <el-table-column
          min-width="15%"
          property="song_number"
          :label="$t('h.task.trackNum')"
          align="center"
        ></el-table-column>
        <el-table-column min-width="15%" show-overflow-tooltip 
          :label="$t('h.task.operate')">
          <template slot-scope="scope">
            <a
              v-if="scope.row.status == '已完成'"
              @click="downloadTask(scope.row)"
              style="color: #ff8c36; cursor: pointer; margin-right: 20px"
              class="el-icon-search iconfont"
              :title="$t('h.task.check')"
            >
            </a>
            <a
              v-else
              style="color: #d2d2d2; cursor: not-allowed; margin-right: 20px"
              class="el-icon-search iconfont"
              title="任务未完成"
            >
            </a>
          </template>
        </el-table-column>
      </el-table>

      <my-pagination
        :page-size="pageSize"
        :current-page="currentPage"
        :total="bondsAllList.length"
        @change-page="changePage"
      ></my-pagination>
    </div>
  </div>
</template>
<script>
export default {
  name: "Task",

  data() {
    return {
      currentPage: 1,
      pageSize: 9,
      bondsAllList: [],
      allList:[],
      tempList: [],
      loading: "",
      predictionResult:[
        {
          "track_name": "aaa",
          "artist": "aaa",
          "audio_link": "url",
          "level": "S"
        }
      ],
      taskName:''
    };
  },

  mounted() {
    this.getdata()
    this.getCurrentPage()
  },

  computed: {
    token: function () {
      return localStorage.getItem("token");
    },
  },

  methods: {
    getFinishedTime (str) {
      if (str === '已完成') return this.$t('h.task.finished')
      if (str === '上传失败') return this.$t('h.task.uploadFailed')

      return this.$t('h.task.expectFinishTime') + ' ' + str + ' ' + this.$t('h.task.expectFinishTime2')
      
    },

    changePage(page) {
      this.currentPage = +page;
      this.currentChangePage(this.bondsAllList, this.currentPage);
    },

    toMark() {
      this.$router.push("/mark");
    },

    async showPrediction(id){
      const res = await this.$store.dispatch('getFocusTrackInfo', {
        token: this.token,
        task_id: id
      })
      if (res.success) {
        // 判断任务是否开启过主打歌预测
        if (res.key_song) {
          this.openFocusTrackPrediction(id)
        } else {
          this.$confirm('This operation will affect subsequent settlement. Do you want to continue?', {
            confirmButtonText: this.$t('h.tool.confirm'),
            cancelButtonText: this.$t('h.tool.cancel'),
            type: 'warning'
          }).then(() => {
            this.changeFocusTrackPermission(id)
          }).catch(() => {})
        }
      } else {
        this.$message({
          message: res.msg,
          type: "error",
          offset: 120
        })
      }
    },

    async changeFocusTrackPermission (id) {
      const res = await this.$store.dispatch('changeFocusTrackPermission', {
        token: this.token,
        task_id: id
      })
      if (res.success) {
        this.openFocusTrackPrediction(id)
      } else {
        this.$message({
          message: res.msg,
          type: "error",
          offset: 120
        })
      }
    },

    openFocusTrackPrediction (id) {
      let host = window.location.host
      if (host === 'pdm.woa.com') {
        host = 'pdm.tencentmusic.com'
      }
      const url = window.location.protocol+"//"+ host +""+window.location.pathname+"#/report-prediction?report_id="+id
      window.open(url)
    },

    downloadTask(row) {
      let name = "";
      if (row.scene_type === "purchase") {
        name = "demo";
      } else if (row.scene_type === "evaluation") {
        name = "assess";
      }
      this.$router.push({
        name: name,
        params: {
          name: row.task_name,
          id: row.task_id,
          ispush: true,
          date: row.create_time,
        },
      });
    },

    //分页方法
    currentChangePage(list, currentPage) {
      this.setCurrentPage()
      let from = (currentPage - 1) * this.pageSize;
      let to = currentPage * this.pageSize;
      this.tempList = [];
      for (; from < to; from++) {
        if (list[from]) {
          this.tempList.push(list[from]);
        }
      }
    },

    async getdata() {
      this.loading = true;
      const result = await this.$store.dispatch("getTask", {
        token: this.token,
      })
      if (result.success) {
        this.bondsAllList = result.task_info_list;
        this.allList = result.task_info_list;
        this.currentChangePage(this.bondsAllList, this.currentPage);
        this.loading = false;
      } else {
        this.$message({
          message: result.msg,
          type: "error",
          offset: 120
        })
      }
    },

    searchList () {
      if (this.taskName[0]){
        let resultList = []
        this.allList.forEach((item)=>{
          if (item.task_name.includes(this.taskName)) {
            resultList.push(item)
          }
        })
        this.bondsAllList = resultList
      }
      else{
        this.bondsAllList = this.allList
      }
      this.currentPage = 1
      this.currentChangePage(this.bondsAllList, this.currentPage)
    },
    setCurrentPage(){
      sessionStorage.setItem('task-list-currentPage',this.currentPage)
    },
    getCurrentPage(){
      this.currentPage = parseInt(sessionStorage.getItem('task-list-currentPage')) || 1
    }
  },
    
};
</script>
<style lang="stylus" scoped>
.main__wrapper{
  height: 100%;
}
.page-task {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.task-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.task-header-text {
  font-size: 18px;
  font-weight: 600;
  line-height: 40px;
  color: #1a1a1a;
}

.task-header-bottom {
  width: 160px;
  height: 40px;
  /* background: #ff8c36; */
  background: #ff8c36;
  border-radius: 30px;
  /* font-family:Regular; */
  font-weight: 400;
  color: #ffffff;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
}

.task-table {
  flex: 1;
  overflow-y: auto;
}

.operate-box {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.filter-box {
  display: flex;
  align-items: center;

  p {
    width: 120px;
  }
}

.scene_type {
  width: 70px;
  height: 18px;
}

.iconfont {
  font-size: 18px;
}
</style>